import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import './index.css'

export default class BlogContent3 extends Component {
  render() {
    return (
      <>
       {/* divider */}
       <div className="p-5"></div>
        <div className="p-1"></div>
        {/* divider */}
      <div className='container'>
      <div className='row'>
      <div className='col-12 col-lg-3'>
        
        </div>
        <div className='col-12 col-lg-6'>
         <Link className='link' to='/blog'> <button className='back-button'>Back</button></Link>
          <img className='img-fluid blog-img' src='/assets/images/blog/3.jpg' alt='ebhoom'/>
        <h1 className='mb-3 font'>Rainwater Harvesting System in TN Man’s Home Helped His Entire Village During Cyclone</h1>
        <p className='font'>M Veeramani from Pudukkottai, Tamil Nadu set up a rainwater harvesting system in his house. This is how it helped Kothamangalam village fight water woes during Cyclone Gaja.</p>
        <div className='mb-3'>
<span className=' d-flex justify-content-start align-items-center blog-list-desc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-calendar me-1" viewBox="0 0 16 16">
  <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
</svg> 12/06/22</span> <span className=' d-flex justify-content-start align-items-center blog-list-desc'><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-clock me-1" viewBox="0 0 16 16">
  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z"/>
  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z"/>
</svg> 3 minute read</span>
</div>
<div></div>


<p className='mb-3 font'>
In the town of Kothamangalam in Tamil Nadu’s Pudukkottai district, 36-year-old M Veeramani credits his wife for his success.<br /><br />


Veeramani and Vanitha’s hometown faces a scarcity of water. People here have to travel at least 1 km to fetch water and Vanitha wasn’t happy about this. She would always urge her husband to look for a better way to access water<br /><br />
Always thinking of solutions, Veeramani on a rainy day rued the fact that all the water was going to waste. So, he decided to store the rainwater in all the buckets and pots that they had. This was sufficient to meet their needs for the next two to three days.
</p>



<h3  className='font'>He realised that if he could find a way to harvest rainwater, their problems would be solved.</h3>

<img className='img-fluid blog-img' src='/assets/images/blog/7.jpg' alt='ebhoom'/>
<p className='mb-3 font'>
In early 2018, Veeramani decided to restore a well in his house to harvest rainwater. The well was used by his grandfather and had been closed by his father. Veeramani then devised a system where the rainwater landing on the roof of his house would get collected in the well through pipes. He opened the well and dug down to 15 feet and added a concrete frame around it.<br /><br />
He designed channels on either side of the roof from where the water flows into the pipes and empties into the well, which has a capacity of 18,000 litres.<br /><br />
However, the system was not a hit in the first attempt. “It took me more than three months to get the system right. When I first laid the concrete structure on the well, it developed cracks within one hour of rain. It was then that I decided to build three layers to drain the water into the well. I removed the concrete cover and constructed a frame lengthwise and around the circumference of the well using metal rods. I covered it with cement and it finally worked,” says Veeramani.
<br /><br />
He uses the water to irrigate the cultivation on his four-acre farm, and for household purposes.
<br /><br />
He spent Rs 1.5 lakh on the rainwater harvesting system. The system was tested as soon as he finished installation during Cyclone Gaja in November 2018.
<br /><br />
“When the cyclone hit, there was no power for two months in our town. All the motors stopped running. But this well was filled to the brim. Thirty families in our town were able to use the water from the well and it proved to be a saviour,” says Veeramani.
<br /><br />
With the borewells drying up in Kothamangalam, and Panchayat water being supplied only once in 2-3 days, this is the solution that the residents of the town found useful. Inspired by Veeramani, other houses have started harvesting rainwater in the village in their plastic water tanks, if they do not have wells.
<br /><br />
But when he started working on this, other villagers criticised him, says Vanitha.
“Neighbours would come and chide Veeramani for ‘wasting’ his money on this system which they deemed a failure. But he was sure that this was the solution to our water woes,” she says, adding, “The same people came to us during the cyclone to fetch water, as there was no electricity and water supply for several days in the aftermath.”
<br /><br />
This system also helped Veeramani harvest rainwater during the torrential rains in November 2021. His brother has also set up a similar system in another well on the farm.

</p>
<img className='img-fluid blog-img' src='/assets/images/blog/8.jpg' alt='ebhoom'/>
<p className='mb-3 font'>At least four other persons in the village have set up small rainwater harvesting systems in their houses too.
<br /><br />
Since Pudukkottai faces a lot of water shortages, the only other alternative for farmers is to dig borewells. But most of the borewells have dried up and farmers have to dig deeper. This would cost them more than Rs 10 lakh as they’d have to dig as deep as 1,000 feet.<br /><br />
“I hope more people in my village practice rainwater harvesting. They will have an abundance of water if each one sets up this system at home. We wouldn’t have to depend on municipal water, which is erratic in its supply,” says Veeramani, who was even felicitated by the Collector for his novel system.
</p>

        
        </div>
        <div className='col-12 collg-3'>
        
        </div>
        </div>
      </div>
        {/* divider */}
        <div className="p-5"></div>
        <div className="p-1"></div>
        {/* divider */}
      </>
    )
  }
}
